const ENV_CONFIG = (function () {
    let CONFIG = {};
    const host = window.location.hostname;
    const process = require('process');
    // prod:    pa.verata.co
    // dev:     verataworklist-dev.azurewebsites.net
    // staging: verataworklist-staging.azurewebsites.net
    // local:   localhost

    // 2fa.verata.co and 2fa-staging.verata.co and 2fa-dev.verata.co 
    // Dev:  https://tokenauthserver2fa-development.azurewebsites.net
    // Staging:  https://tokenauthserver2fa-staging.azurewebsites.net
    // Production:  https://tokenauthserver2fa.azurewebsites.net


    // ----------------------------------------------------------------
    //
    //      NOTE: API endpoints must be allowed in the CSP policy
    //            Edit: /dist/server_secureHeaders.js to include URL
    //            Requirement removed 7/15/20
    // 
    // ----------------------------------------------------------------

    const APPINSIGHTS = "InstrumentationKey=22ad1ea6-442c-468b-8d03-480c995eac2e;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/"
    const TRAINING_LINK = "https://pathway-training.humatahealth.com/Training/AuthorizeTrainingPage";

    const attestationDevelopmentUrl = `https://salmon-sand-0ed8c560f-test.eastus2.3.azurestaticapps.net`
    const attestationTestUrl = `https://salmon-sand-0ed8c560f-test.eastus2.3.azurestaticapps.net`
    const attestationStagingUrl = `https://salmon-sand-0ed8c560f-test.eastus2.3.azurestaticapps.net`
    const attestationProdUrl = `https://flblue.prod.attestation.azp.availity.com`
    CONFIG.enableHealthHelpDirectConnect = true;
    CONFIG.enableEviCoreDirectConnect = false;

    switch (host) {
        case 'pa.verata.co':
        case 'verataworklist.azurewebsites.net':
            CONFIG.env_name = 'Production';
            CONFIG.env_api = `https://tokenauthserver2018.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationProdUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.azp.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = false;
            break;
        case '2fa.verata.co':
            CONFIG.env_name = 'Production';
            CONFIG.env_api = `https://tokenauthserver2fa.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.non2faUrl = `https://non2FA.pathway.humatahealth.com`;
            CONFIG.attestation = {
                host: attestationProdUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.azp.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = false;
            break;
        case 'sso.verata.co':
            CONFIG.env_name = 'Production';
            CONFIG.env_api = `https://tokenauthserver2fa.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationProdUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.azp.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = false;
            break;
        case 'pathway.humatahealth.com':
            CONFIG.env_name = 'Production';
            CONFIG.env_api = `https://tokenauthserver2fa.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.non2faUrl = `https://non2FA.pathway.humatahealth.com`;
            CONFIG.attestation = {
                host: attestationProdUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.azp.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = false;
            break;
        case 'pathway.humatahealth.com':
            CONFIG.env_name = 'Production';
            CONFIG.env_api = `https://tokenauthserver2fa.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.non2faUrl = `https://pathway-non2fa.humatahealth.com`;
            CONFIG.attestation = {
                host: attestationProdUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.azp.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = false;
            break;
        case 'pathway-non2fa.humatahealth.com':
        case 'non2fa.pathway.humatahealth.com':
            CONFIG.env_name = 'Production';
            CONFIG.env_api = `https://tokenauthserver2018.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationProdUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.azp.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = false;
            break;
        case 'staging.pathway.humatahealth.com':
        case '2fa-staging.verata.co':
            CONFIG.env_name = 'Staging 2fa';
            CONFIG.env_api = `https://tokenauthserver2fa-staging.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.non2faUrl = `https://non2FA.staging.pathway.humatahealth.com`;
            CONFIG.attestation = {
                host: attestationStagingUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enablePaExports = true;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enableEviCoreDirectConnect = true;
            break;
        case 'pathway-staging.humatahealth.com':
            CONFIG.env_name = 'Staging 2fa';
            CONFIG.env_api = `https://tokenauthserver2fa-staging.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.non2faUrl = `https://pathway-staging-non2fa.humatahealth.com`;
            CONFIG.attestation = {
                host: attestationStagingUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enablePaExports = true;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enableEviCoreDirectConnect = true;
            break;

        case 'sso-staging.verata.co':
            CONFIG.env_name = 'SSO Staging';
            CONFIG.env_api = `https://tokenauthserver2fa-staging.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationStagingUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enablePaExports = true;
            break;
        case 'pathway-dev.humatahealth.com':
        case 'dev.pathway.humatahealth.com':
        case '2fa-dev.verata.co':
            CONFIG.env_name = 'Dev 2fa';
            CONFIG.env_api = `https://tokenauthserver2fa-development.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.non2faUrl = `https://non2FA.dev.pathway.humatahealth.com`;
            CONFIG.attestation = {
                host: attestationDevelopmentUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.enablePaExports = true;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enableEviCoreDirectConnect = true;
            break;
        case 'sso-dev.verata.co':
            CONFIG.env_name = 'SSO Dev';
            CONFIG.env_api = `https://tokenauthserver2fa-development.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationDevelopmentUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enablePaExports = true;
            break;
        case 'pathway-staging-non2fa.humatahealth.com':
        case 'non2fa.staging.pathway.humatahealth.com':
        case 'verataworklist-staging.azurewebsites.net':
            CONFIG.env_name = 'Staging';
            CONFIG.env_api = `https://tokenauthserver2018-staging.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationStagingUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = true;
            CONFIG.enableEviCoreDirectConnect = true;
            break;
        case 'pathway-dev-non2fa.humatahealth.com':
        case 'non2fa.dev.pathway.humatahealth.com':
        case 'verataworklist-techupdate.azurewebsites.net':
            CONFIG.env_name = 'WebpackUpdate (Dev)';
            CONFIG.env_api = `https://tokenauthserver2018-dev.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationDevelopmentUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = true;
            CONFIG.enableEviCoreDirectConnect = true;
            break;
        case 'pathway-demo.humatahealth.com':
        case 'verataworklist-demo.azurewebsites.net':
        case 'pathwaydemo.verata.co':
        case 'demo.pathway.humatahealth.com':
            CONFIG.env_name = 'Demo';
            CONFIG.env_api = `https://tokenauthserver2018-demo.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationTestUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enablePaExports = true;
            CONFIG.demoMode = true;
            break;
        case 'verataworklist-test.azurewebsites.net':
        case 'pathway-beta.humatahealth.com':
            CONFIG.env_name = 'ProdTest';
            CONFIG.env_api = `https://tokenauthprodtest.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationProdUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.azp.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = true;
            break;
        case 'verataworklist-dev.azurewebsites.net':
            CONFIG.env_name = 'Development';
            CONFIG.env_api = `https://tokenauthserver2018-dev.azurewebsites.net/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.attestation = {
                host: attestationDevelopmentUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enablePaExports = true;
            CONFIG.enableEviCoreDirectConnect = true;
            break;
        default:
            CONFIG.env_name = 'Local';
            // if (process.env.API === 'local') CONFIG.env_api = `https://localhost:44309/api/`;
            // CONFIG.env_api = `https://tokenauthserver2018-staging.azurewebsites.net/api/`;
            CONFIG.env_api = `https://localhost:44309/api/`;
            CONFIG.app_insights = APPINSIGHTS;
            CONFIG.non2faUrl = `https://non2FA.dev.pathway.humatahealth.com`;
            CONFIG.attestation = {
                host: attestationStagingUrl,
                api: CONFIG.env_api,
                gorilla: 'https://flblue.stage.azs.availity.com/'
            }
            CONFIG.training_url = TRAINING_LINK;
            CONFIG.enableHealthHelpDirectConnect = true;
            CONFIG.enablePaExports = true;
            CONFIG.enableEviCoreDirectConnect = true;
            break;
    }
    return CONFIG
})()

export default ENV_CONFIG
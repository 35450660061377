<template>
  <div id="app" v-bind:class="$route.name">
    <transition name="slideDown">
      <div
        id="global-message"
        v-if="globalMessage.message"
        v-bind:class="globalMessage.type"
      >
        {{startGlobalMessageTimeout(globalMessage.timeout)}}
        <span
          class="mainMsg"
          v-html="globalMessage.message"
          @click="changeRoute(globalMessage.destination)"
        ></span>
        <span class="subMsg">{{ globalMessage.details }}</span>
        <span class="close" @click="clearGlobal"
          ><i class="fa fa-times-circle"></i
        ></span>
      </div>
    </transition>
    <div v-if="(userRole && readOnlyUser)" id="readOnlyUserHeader">
      Read Only View
    </div>
    <header v-bind:class="userRole && readOnlyUser ? 'readOnlyUser' : ''">
      <router-link v-if="userIsValidated()" :to="{ name: 'Worklist' }">
        <h1>Pathway</h1>
      </router-link>
      <div class="vrule" v-if="userIsValidated() && currentCustomerLogo"></div>
      <img alt="Customer Logo" id="customer-logo" v-bind:src="currentCustomerLogo" v-if="userIsValidated() && currentCustomerLogo && !gettingCustomerLogo"/>

      <div class="org" v-if="currUser.organization && !linkedAccounts.length && !currentCustomerLogo && !gettingCustomerLogo">
        <span
          >For: <strong>{{ currUser.organization }}</strong></span
        >
      </div>
      
      <div class="org" v-if="linkedAccounts.length && !currentCustomerLogo">
        <span
          >For: {{ currUser.organization }}
          <select v-model="selectedAccount" v-on:change="swithcUserAccount">
            <option value="null" selected>Change Organization</option>
            <option value="null" disabled="true">
              Current {{ currUser.organization }} - as {{ currUser.userName }}
            </option>
            <option
              :value="account.paPersonId"
              :key="account.customerName"
              v-for="account in linkedAccounts"
            >
              {{ account.customerName }} - as {{ account.userName }}
            </option>
          </select>
        </span>
      </div>
      
      <nav>
        <router-link
          v-if="currUser.firstName"
          :to="{ name: 'Archive', params: { root: true } }"
        >
          <span>Archive</span>
        </router-link>
        <router-link
          v-if="currUser.firstName"
          :to="{ name: 'Worklist', params: { root: true } }"
        >
          <span v-if="readOnlyUser">Request List</span>
          <span v-else>PA Worklist</span>
        </router-link>

        <!-- Admin Nav -->
        <div
          v-if="canViewAdminTools"
          v-bind:class="nav.admin ? 'open' : ''"
        >
          <div class="navItem primaryNav" @click="toggleNav('admin')">
            Admin Tools
          </div>
          <!-- Old version had v-clickoutside="toggleNav('admin')" on the subnav item. Adding that here breaks it -->
          <div class="drop subNav" v-if="nav.admin">
            <ul v-clickoutside="closeAllNav">
              <li @click="changeRoute('Reports')" v-if="canViewReporting">
                <span
                  ><i class="fa fa-chart-bar"></i> Reporting &amp; Export</span
                >
              </li>
              <li
                @click="changeRoute('CSM/CustomerSettings')"
                v-if="canViewCsmSettings"
              >
                <span><i class="fa fa-cog"></i> Customer Settings</span>
              </li>
              <li @click="changeRoute('UserManagement')" v-if="canViewUserManagement">
                <span><i class="fa fa-users"></i> Manage Users</span>
              </li>
              <li
                @click="changeRoute('SystemSettings')" v-if="canViewSystemSettings">
                <span><i class="fa fa-cog"></i> System Settings</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- User Nav -->
        <div v-if="currUser.firstName" v-bind:class="nav.user ? 'open' : ''">
          <div class="navItem primaryNav" @click="toggleNav('user')">
            <div
              class="splat"
              v-if="worklistNotifications.includes('NewWorklistVersion')"
            >
              Updated
            </div>
            <span v-if="!showUserSwitch">Hello {{ currUser.firstName }} </span>
            <select v-if="showUserSwitch" v-model="activeUser">
              <option
                v-for="worker in workers"
                :key="worker.paPersonId"
                v-bind:value="worker.paPersonId"
              >
                {{ worker.firstName + " " + worker.lastName }}
              </option>
            </select>
          </div>
          <div class="drop subNav" v-if="nav.user">
            <ul v-clickoutside="closeAllNav">
              <li @click="changeRoute('UserProfile')">
                <span><i class="fa fa-address-card"></i> My Account</span>
              </li>
              <li @click="signOut()">
                <span><i class="fa fa-times-circle"></i> Sign Out</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Help Nav -->
        <div v-if="currUser.firstName" v-bind:class="nav.help ? 'open' : ''">
          <div class="navItem primaryNav" @click="toggleNav('help')">
            <i class="fas fa-question-circle"></i>
          </div>
          <div class="drop subNav" v-if="nav.help">
            <ul v-clickoutside="closeAllNav">
              <li @click="showSupport('an issue')">
                <span><i class="fas fa-envelope"></i>Contact Support</span>
              </li>
              <li @click="toggleReleaseNoteDisplay">
                <div
                  class="splat"
                  v-if="worklistNotifications.includes('NewWorklistVersion')"
                >
                  Updated
                </div>
                <span><i class="fas fa-gift"></i> What's New</span>
              </li>
              <li @click="downloadUserGuide">
                <span><i class="fas fa-book"></i>User Guide</span>
              </li>
              <li @click="showSupport('training')">
                <span><i class="fas fa-graduation-cap"></i>Training</span>
              </li>
            </ul>
          </div>

          <ContactSupport
            v-if="showContactSupportModal"
            :default-subject="contactSupportDefaultSubject"
            @close="toggleContactSupportModal"
          />
          <ReleaseNotes
            v-if="showReleaseNotes.show"
            @close="closeReleaseNotes"
          />
          <UMSPopupModal/>
        </div>

        <!-- History Nav -->
        <div
          v-if="currUser.firstName"
          v-bind:class="nav.history ? 'open' : ''"
        >
          <div class="navItem" @click="toggleNav('history')">
            <i class="fa fa-clock-rotate-left"></i>
          </div>
          <div
            class="drop history"
            v-if="nav.history"
            v-clickoutside="closeAllNav"
          >
            <transition name="loading">
              <GenericLoader 
                v-if="loadingRecentlyViewedRequests" 
                displaySize="small"
              >
                Loading Recently Viewed Requests
              </GenericLoader>
            </transition>
            <transition-group 
              name="loading" 
              tag="div" 
              v-if="!loadingRecentlyViewedRequests"
            >
              <h5 v-if="recentlyViewedRequests.length" key="recently-reviewed-header">
                <span>Recently Viewed Requests</span>
              </h5>
              <ul v-if="recentlyViewedRequests.length" key="recently-reviewed-requests">
                <li v-for="request in recentlyViewedRequests" :key="request.paRequestId">
                <div class="subject" @click="navigateToRequest(request.paRequestId)" >
                    <div class="activity">
                      <span v-if="request.lastName">{{ request.lastName }}, </span> 
                      <span v-if="request.firstName">{{request.firstName}} </span>
                      <span class="more-info" v-if="request.cptCode" >- {{ request.cptCode }} </span>
                      <span class="more-info" v-if="request.payerName">- {{ request.payerName }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-if="!recentlyViewedRequests.length" class="empty" key="recently-reviewed-empty">
                <li>No Recently Viewed Requests!</li>
              </ul>
            </transition-group>
          </div>
        </div>

        <!-- Notifications -->
        <div
          v-if="currUser.firstName"
          v-bind:class="nav.notifications ? 'open' : ''"
        >
          <div class="navItem" @click="toggleNav('notifications')">
            <i class="fa fa-bell"></i>
            <span
              class="count"
              v-if="
                userNotifications.length ||
                faxSubmission.faxingSubmissions.length ||
                downloadsInProgress.length ||
                completedDownloads.length ||
                failedDownloads.length ||
                backgroundReportsFinished.length ||
                backgroundReportsError.length
              "
              >{{ notificationCount }}</span
            >
            <span
              class="bgProcess"
              v-if="faxSubmission.faxingSubmissions.length || downloadsInProgress.length"
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div
            class="drop inbox"
            v-if="nav.notifications"
            v-clickoutside="closeAllNav"
          >

            <!-- FAX SUBMISSION NOTIFICATIONS -->
            <h5 v-if="faxSubmission.faxingSubmissions.length">
              <span>System Activity</span>
            </h5>
            <ul
              v-if="faxSubmission.faxingSubmissions.length"
              class="systemActivity"
            >
              <li
                v-for="notification in faxSubmission.faxingSubmissions"
                :key="notification.when"
                class="message"
              >
                <div
                  class="subject"
                  @click="
                    changeRoute(
                      `request/${notification.paRequestId}/?tab=submission`
                    )
                  "
                >
                  <div class="activity">Sending Fax</div>
                  <h6 v-if="notification.patient && notification.companyName">
                    {{ notification.patient }} to {{ notification.companyName }}
                  </h6>
                  <h6 v-if="!notification.patient || !notification.companyName">
                    <i class="fa fa-spinner fa-spin"></i> Getting Submission
                    Details
                  </h6>
                  <div class="info">
                    Processing
                    <span>Submitted: {{ getFromNow(notification.when) }}</span>
                  </div>
                </div>
                <div class="actionIcon">
                  <span class="fa-stack">
                    <i class="far fa-circle fa-stack-1x"></i>
                    <i class="fas fa-spinner-third fa-stack-1x fa-spin"></i>
                  </span>
                </div>
              </li>
            </ul>
            <!-- FAX SUBMISSION NOTIFICATIONS -->

            <!-- USER NOTIFICATIONS -->
            <h5 v-if="userNotifications.length">
              <span>Notifications</span>
              <span class="action" @click="clearAllNotifications()"
                >Clear All</span
              >
            </h5>
            <ul v-if="userNotifications.length">
              <li
                v-for="notification in userNotifications"
                :key="notification.when"
                class="message"
              >
                <div
                  class="subject"
                  @click="navigateToNotification(notification)"
                >
                  <div class="activity">{{ notification.message }}</div>
                  <h6>{{ notification.title }}</h6>
                  <div class="info">
                    <span>{{ getFromNow(notification.when) }}</span>
                  </div>
                </div>
                <div
                  class="remove"
                  @click="removeNotification(notification.when)"
                >
                  <i class="far fa-trash-alt"></i>
                </div>
              </li>
            </ul>
            <!-- USER NOTIFICATIONS -->

            <!-- DOWNLOAD  NOTIFICATIONS -->

            <!-- DOWNLOADS IN PROGRESS -->
            <h5 v-if="downloadsInProgress.length">
              <span>Export Activity</span>
            </h5>
            <ul
              v-if="downloadsInProgress.length"
              class="systemActivity"
            >
              <li
                v-for="notification in downloadsInProgress"
                :key="notification.when"
                class="message"
              >
                <div
                  class="subject"
                  @click="checkDataExportStatus(notification)"
                >
                  <div class="activity">Exporting {{ notification.from }}</div>
                  <div class="info">
                    Processing
                    <span>Submitted: {{ getFromNow(notification.when) }}</span>
                  </div>
                </div>
                <div class="actionIcon">
                  <span class="fa-stack">
                    <i class="far fa-circle fa-stack-1x"></i>
                    <i class="fas fa-spinner-third fa-stack-1x fa-spin"></i>
                  </span>
                </div>
              </li>
            </ul>
            <!-- DOWNLOADS IN PROGRESS -->

            <!-- COMPLETED DOWNLOADS -->
            <h5 v-if="completedDownloads.length">
              <span>Completed</span>
            </h5>
            <ul
              v-if="completedDownloads.length"
              class="systemActivity"
            >
              <li
                v-for="(notification, index) in completedDownloads"
                :key="notification.when"
                class="message"
              >
                <div
                  class="subject"
                  @click="downloadCompletedNotification(notification)"
                >
                  <div class="activity">{{ notification.from }} Export Complete</div>
                  <div class="info">
                    <p class="mt-0.5 mb-0.5"><b v-if="index == 0">Most Recent - </b><b>Click To Download</b></p>
                    <span>{{ getFromNow(notification.when) }}</span>
                  </div>
                </div>
                <div
                  class="remove"
                  @click="removeCompletedDownloadNotification(notification)"
                >
                  <i class="far fa-trash-alt"></i>
                </div>
              </li>
            </ul>
            <!-- COMPLETED DOWNLOADS -->

            <!-- FAILED DOWNLOADS -->
            <h5 v-if="failedDownloads.length">
              <span>Failed Exports</span>
            </h5>
            <ul
              v-if="failedDownloads.length"
              class="systemActivity"
            >
              <li
                v-for="notification in failedDownloads"
                :key="notification.when"
                class="message"
              >
                <div
                  class="subject"
                >
                  <div class="activity">Download Failed</div>
                  <div class="info">
                    <p class="mt-0.5 mb-0.5"><b>{{ notification.response }}</b></p>
                    <span>{{ getFromNow(notification.when) }}</span>
                  </div>
                </div>
                <div
                  class="remove"
                  @click="removeFailedDownloadNotification(notification)"
                >
                  <i class="far fa-trash-alt"></i>
                </div>
              </li>
            </ul>
            <!-- FAILED DOWNLOADS -->
            <!-- DOWNLOAD NOTIFICATIONS -->

            <!-- REPORTS NOTIFICATIONS  -->
            <!-- REPORTS RUNNING IN BACKGROUND  -->
            <h5 v-if="backgroundReportsProcessing.length">
              <span>Reports being generated</span>
            </h5>
            <ul
                v-if="backgroundReportsProcessing.length"
                class="systemActivity"
            >
              <li
                  v-for="(report, index) in backgroundReportsProcessing"
                  :key="report.when"
                  class="message"
              >
                <div class="subject" @click="checkReportStatus(report)">
                  <div class="activity">Generating {{ report.name }}</div>
                  <div class="info">
                    <p class="mt-0.5 mb-0.5">{{ report.startEndStr }}</p>
                    <span>{{ getFromNow(report.when) }}</span>
                  </div>
                </div>
              </li>
            </ul>
            <!-- REPORTS RUNNING IN BACKGROUND  -->

            <!-- REPORTS FINISHED  -->
            <h5 v-if="backgroundReportsFinished.length">
              <span>Reports Ready for viewing</span>
            </h5>
            <ul
                v-if="backgroundReportsFinished.length"
                class="systemActivity"
            >
              <li
                  v-for="(report, index) in backgroundReportsFinished"
                  :key="report.when"
                  class="message"
              >
                <div class="subject">
                  <div class="activity">{{ report.name }}</div>
                  <div class="info" @click="viewReportNotification(report)">
                    <p class="mt-0.5 mb-0.5">{{ report.startEndStr }}</p>
                    <div style="display:flex; justify-content: flex-start; font-size: 14px;" class="mt-0.5 mb-0.5"><div style="text-decoration: underline!important;" >View</div></div>
                  </div>
                </div>
                <div class="remove" @click="removeReportsNotification(report)" >
                  <i class="far fa-trash-alt"></i>
                </div>
              </li>
            </ul>
            <!-- REPORTS FINISHED -->

            <!-- FAILED REPORTS -->
            <h5 v-if="backgroundReportsError.length">
              <span>Failed Reports</span>
            </h5>
            <ul v-if="backgroundReportsError.length" class="systemActivity">
              <li v-for="report in backgroundReportsError" :key="report.when" class="message">
                <div class="subject">
                  <div class="activity">Report Failed</div>
                  <div class="info">
                    <p class="mt-0.5 mb-0.5"><b>{{ report.name }}</b></p>
                    <span>{{ report.startEndStr }}</span>
                  </div>
                </div>
                <div class="remove" @click="removeFailedReportNotification(report)">
                  <i class="far fa-trash-alt"></i>
                </div>
              </li>
            </ul>
            <!-- FAILED REPORTS -->
            <!-- REPORTS NOTIFICATIONS  -->

            <ul
              v-if="
                !userNotifications.length &&
                !faxSubmission.faxingSubmissions.length &&
                !downloadsInProgress.length &&
                !failedDownloads.length &&
                !completedDownloads.length &&
                !backgroundReportsProcessing.length &&
                !backgroundReportsFinished.length &&
                !backgroundReportsError.length
              "
              class="empty"
            >
              <li>All done for now!</li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <router-view />

    <!-- 
      Landing destination for Pathway modals. Since there are times where
      component composition has limits to where modals could be placed,
      utilizing portals allows us to compose modals in places where
      they make the most sense without the need to over-refactor or create
      a need for a more global modal system.

      The BaseModal component will always use the body as the destination

      https://v2.portal-vue.linusb.org/guide/getting-started.html#usage
    -->
    <portal-target name="body"></portal-target>

    <NewVersionModal v-if="loggedIn"/>

    <WarnModal v-if="tokenExpires && tokenExpires < 60 && tokenExpires > 0">
      <div>
        <i class="far fa-exclamation-triangle"></i>
        <h3>Inactivity Warning</h3>
        <p>
          Since you've been inactive for a while, you will be automatically
          logged out in {{ tokenExpires }}s.
        </p>
        <div class="buttonWrap">
          <button @click="extendSession">Continue Session</button>
          <a class="ml-2.5" href="#" @click.prevent="signOut">Sign Out</a>
        </div>
      </div>
    </WarnModal>

    <SiteFooter />

  </div>
</template>

<script>
import '../node_modules/virtual-select-plugin/dist/virtual-select.min.js'
import '../node_modules/virtual-select-plugin/dist/virtual-select.min.css'

  import GenericLoader from '@/components/GenericLoader'
  import axios from "axios";
  import moment from "moment";
  import ENV_CONFIG from "@/config/environment";
  import { mapGetters, mapState } from "vuex";
  import SiteFooter from "@/components/SiteFooter";
  import clickoutside from "@/directives/clickoutside";
  import UMSPopupModal from '@/components/UMSMessages.PopupModal.vue';
  import NewVersionModal from '@/components/UMSMessages.NewVersionModal.vue';
  import { BlobService }  from '@/services/blob'

  export default {
    name: "app",
    directives: { clickoutside },
    components: {
      GenericLoader,
      SiteFooter,
      UMSPopupModal,
      NewVersionModal,
      WarnModal: () => import('./components/Modal.WarnModal.vue'),
      ContactSupport: () => import('./components/ContactSupport.vue'),
      ReleaseNotes: () => import('./components/ReleaseNotes.vue'),
    },
    data() {
      return {
        EXPORTING_DATA: false,
        requests: [],
        showUserSwitch: process.env.ALLOW_USER_SWITCH,
        selectedAccount: null,
        newReleaseNotes: true,
        showContactSupportModal: false,
        contactSupportDefaultSubject: null,
        nav: {
          admin: false,
          user: false,
          help: false,
          notifications: false,
          history: false
        },
      };
    },
    computed: {
      ...mapState({
        currUser: (state) => state.currUser,
        workers: (state) => state.workers,
        loggedIn: (state) => state.auth.loggedIn,
        globalMessage: (state) => state.globalMessage,
        tokenExpires: (state) => state.tokenExpires,
        timerInterval: (state) => state.auth.timerInterval,
        currentTabChanges: (state) => state.currentTabChanges,
        linkedAccounts: (state) => state.linkedAccounts,
        worklistNotifications: (state) => state.worklistNotifications,
        userNotifications: (state) => state.userNotifications,
        recentlyViewedRequests: (state) => state.recentlyViewedRequests,
        loadingRecentlyViewedRequests: (state) => state.loadingRecentlyViewedRequests,
        faxSubmission: (state) => state.faxSubmission,
        customerSettings: (state) => state.customerSettings,
        showReleaseNotes: (state) => state.showReleaseNotes,
        paEventFeedNoteChanges: (state) => state.paEventFeedNoteChanges,
        refreshBrowser: (state) => state.browserRefresh.refreshBrowser,
      }),
      ...mapState("customerLogos", ["currentCustomerLogo", "gettingCustomerLogo"]),
      ...mapState("downloadQueue", ["downloadsInProgress", "completedDownloads", "failedDownloads"]),
      ...mapState("reporting", ["backgroundReportsProcessing", "backgroundReportsFinished", "backgroundReportsError"]),
      ...mapGetters(["userRole", "readOnlyUser"]),
      trainingLink: function () {
        return `${ENV_CONFIG.training_url}?token=${sessionStorage.getItem("token")}`
      },
      canViewManageUsers: function () {
        return this.userRole && this.userRole.includes('Manager') && !this.readOnlyUser
      },
      canViewCsmSettings: function () {
        return this.userRole &&
               this.userRole.includes('VerataCustomerServiceManager') && 
               !this.readOnlyUser
      },
      canViewSystemSettings: function () {
        return this.userRole &&
               this.userRole.includes('SystemAdministrator') &&
               !this.readOnlyUser
      },
      canViewReporting: function () {
        return this.userRole && (this.userRole.includes('Manager') || this.userRole.includes('ReportViewer'))
      },
      canViewAdminTools: function () {
        return this.userRole && (
          this.userRole.includes('Manager') || 
          this.userRole.includes('ReportViewer') || 
          this.userRole.includes('VerataCustomerServiceManager') ||
          this.userRole.includes('SystemAdministrator')
        )
      },
      canViewUserManagement: function () {
        return this.userRole.includes('Manager') && !this.readOnlyUser
      },
      notificationCount: function () {
        let theCount =
          this.userNotifications.length +
          this.faxSubmission.faxingSubmissions.length +
          this.downloadsInProgress.length +
          this.completedDownloads.length +
          this.failedDownloads.length +
          this.backgroundReportsFinished.length +
          this.backgroundReportsError.length;
        if (
          !this.userNotifications.length &&
          !this.faxSubmission.faxingSubmissions.length &&
          !this.downloadsInProgress.length &&
          !this.completedDownloads.length &&
          !this.failedDownloads.length &&
          !this.backgroundReportsFinished.length &&
          !this.backgroundReportsError.length
        )
          return "";
        if (theCount > 9) return "9+";
        return theCount;
      },
      activeUser: {
        get: function () {
          return this.currUser.paPersonId;
        },
        set: function () {
          this.updateCurrUser(event);
        },
      },
    },
    mounted: function () {
    if (this.loggedIn) {
        let isIE = this.$browserDetect.isIE;
        this.$store.commit("SET_IS_IE", isIE);
        this.checkSessionExpires();

        this.$store.dispatch("faxSubmission/GET_SESSION_FAXES");
      }
    },
    methods: {
      checkDataExportStatus: function (notification) {
        this.$store.dispatch("downloadQueue/CHECK_DOWNLOAD_STATUS", notification)
      },
      checkReportStatus: function (report) {
        this.$store.dispatch("reporting/CHECK_DOWNLOAD_STATUS", report);
      },
      downloadCompletedNotification: function (notification) {
        BlobService.download({ notification })
      },
      removeCompletedDownloadNotification: function (notification) {
        this.$store.dispatch("downloadQueue/REMOVE_COMPLETED_DOWNLOAD", notification)
      },
      removeFailedDownloadNotification: function (notification) {
        this.$store.dispatch("downloadQueue/REMOVE_FAILED_DOWNLOAD", notification)
      },
      viewReportNotification: function(report) {
        const { href } = this.$router.resolve({
          name: 'DynamicReportWithId',
          params: { reportResultsId: report.reportResultsId } // Route parameters
        });

        if (this.$route.path !== href) {
          if (this.$route.path.startsWith('/DynamicReport/')) {
            this.$router.replace(href);
            this.$store.commit('reporting/SET_REPORT_DATA_BY_ID', parseInt(report.reportResultsId));
          } else {
            this.$router.push(href);
          }
        }
      },
      removeReportsNotification: function (report) {
        this.$store.dispatch("reporting/REMOVE_FINISHED_REPORTS", report)
      },
      removeFailedReportNotification: function (report) {
        this.$store.dispatch("reporting/REMOVE_FAILED_REPORTS", report)
      },
      userIsValidated: function () {
        const twoFactorRequired = sessionStorage.getItem('twoFactorRequired') == "true"
        const twoFactorVerified = sessionStorage.getItem('twoFactorVerified') == "true"

        return this.loggedIn && (twoFactorRequired ? twoFactorVerified : true)
      },
      signOut: function () {
        this.closeAllNav();
        if (this.currentTabChanges || this.paEventFeedNoteChanges) {
          this.$store.commit("SET_UNSAVED_MODAL_DISPLAY", true);
          return;
        }

        this.$store.dispatch("auth/SIGN_OUT");
      },
      startGlobalMessageTimeout: function (milliseconds){
        //do not activate the timeout if the number is too small
        if (milliseconds && milliseconds > 100){
          setTimeout(() => {
             this.$store.dispatch('CLEAR_GLOBAL_MESSAGE')
          }, milliseconds)
        }
      },
      getFromNow: function (date) {
        return moment.utc(date).fromNow();
      },
      removeNotification: function (timeStamp) {
        this.$store.dispatch("REMOVE_USER_NOTIFICATION", timeStamp);
      },
      clearAllNotifications: function () {
        this.$store.dispatch("SET_USER_NOTIFICATIONS_IN_TEMP_STORAGE", null);
        this.$store.commit("SET_USER_NOTIFICATIONS", []);
        this.closeAllNav();
      },
      showSupport: function ( subject = null ) {
        this.contactSupportDefaultSubject = subject;
        this.showContactSupportModal = true;
      },
      toggleContactSupportModal: function ( subject = null ) {
        this.contactSupportDefaultSubject = subject;
        this.showContactSupportModal = !this.showContactSupportModal;
      },
      toggleReleaseNoteDisplay: function () {
        let params = {
          show: !this.showReleaseNotes,
          source: "release",
        };
        this.$store.commit("SET_RELEASE_NOTES_DISPLAY", params);
        // this.showReleaseNotes = !this.showReleaseNotes
        this.closeAllNav();
      },
      downloadUserGuide: async function () {
        let url = `${
          ENV_CONFIG.env_api
        }WorklistVersion/PathwayUserGuide?access_token=${sessionStorage.getItem(
          "token"
        )}`;

        if (!window.navigator || !window.navigator.msSaveBlob) {
          window.open(url, "_blank");
          return;
        }

        await axios
          .get(`${url}`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
              Pragma: "no-cache",
            },
            responseType: "arraybuffer",
          })
          .then(
            (response) => {
              var newBlob = new Blob([response.data], {
                type: "application/pdf",
              });
              window.navigator.msSaveBlob(newBlob, "Pathway-User-Guide.pdf");
            },
            (err) => {
              window.console.log(err);
            }
          );
      },
      closeReleaseNotes: function () {
        this.$store.commit("SET_RELEASE_NOTES_DISPLAY", false);
        if (this.refreshBrowser) {
          window.location.reload(true)
          this.$store.commit('browserRefresh/SET_REFRESH_BROWSER', false)
        }
      },
      swithcUserAccount: function () {
        this.$store
          .dispatch("SWITCH_TO_LINKED_ACCOUNT", this.selectedAccount)
          .then((_) => {
            sessionStorage.removeItem("worklistFilter");
            sessionStorage.removeItem("worklistColumns");
          });
      },
      cancelUserSwitch: function () {
        this.selectedAccount = null;
      },
      navigateToNotification: function (notification) {
        this.$store.dispatch("REMOVE_USER_NOTIFICATION", notification.when);
        this.changeRoute(notification.destination);
      },
      navigateToRequest: function (requestId) {
        // Setting the page to 0 ensures there will be no "Next" or "Previous" button in the PA
        // This is intended since the user is navigating from the recently viewed list, so we don't know what page we're on
        sessionStorage.setItem("worklistCurrentPage", 0);
        this.$store.commit('SET_WORKLIST_CURRENT_PAGE', 0);
        var route = `request/${requestId}`;
        this.changeRoute(route);
      },
      changeRoute: function (path) {
        if (!path) return;
        this.$router.push(`/${path}`).then(() => {
          this.closeAllNav();
        });
      },
      updateCurrUser: function (event) {
        this.$store.dispatch("UPDATE_CURR_USER", event.target.value);
      },
      clearGlobal: function () {
        this.$store.dispatch("CLEAR_GLOBAL_MESSAGE");
      },
      getResetPasswordToken: function () {
        axios
          .get(`${ENV_CONFIG.env_api}Auth/GetPasswordResetToken`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then(
            (response) => {
              this.$router.push({
                path: `/ResetPassword?token=${response.data.token}`,
              });
            },
            (err) => {
              this.$store.commit("SET_GLOBAL_MESSAGE", [
                "error",
                "Unable to generate reset token",
              ]);
              console.log(err);
            }
          );
      },
      extendSession: function () {
        this.$store.dispatch("auth/REFRESH_AUTH_TOKEN");
      },
      checkSessionExpires: function () {
        if (this.loggedIn) {
          setTimeout(() => {
            this.$store.dispatch("GET_TOKEN_EXPIRE_TIME");
            this.checkSessionExpires();
          }, this.timerInterval);
        }
      },
      checkPasswordExpiration: function () {
        // Olive users will have a passwordExpirationDate set to null
        // so their passwords will never expire.
        if (this.currUser.passwordExpirationDate) {
          const todaysDate = moment();
          const passwordExpirationDate = moment(
            this.currUser.passwordExpirationDate
          );

          const diff = passwordExpirationDate.diff(todaysDate, "Days");

          if (diff <= 7) {
            if (diff === 0) {
              this.$store.commit("SET_GLOBAL_MESSAGE", [
                "error",
                `Your password expires tomorrow. Click here to reset it`,
                null,
                "UserProfile/ChangePassword",
              ]);
            } else {
              this.$store.commit("SET_GLOBAL_MESSAGE", [
                "error",
                `Your password is expiring in ${diff} day(s). Click here to reset it`,
                null,
                "UserProfile/ChangePassword",
              ]);
            }
          }
        }
      },
      showNav: function (targ) {
        if (!this.nav[targ]) {
          // don't think we need to hide others
          // click outside should do this
          Object.keys(this.nav)
            .filter((key) => key != targ)
            .forEach((key) => {
              this.nav[key] = false;
            });
          this.nav[targ] = true;
        }
      },
      hideNav: function (targ) {
        if (this.nav[targ]) {
          this.nav[targ] = false;
        }
      },
      toggleNav: function (targ) {
        this.nav[targ] = !this.nav[targ];
        
        if (targ === 'history') {
          this.$store.dispatch('GET_RECENTLY_VIEWED_REQUESTS');
        }
      },
      closeAllNav: function () {
        Object.keys(this.nav).forEach((key) => {
          this.nav[key] = false;
        });
      },
      startDownloadsTimer: function() {
        if (!this.downloadsTimer) {
          this.downloadsTimer = setInterval(() => {
            this.downloadsInProgress.forEach(dlInProgress => {
              this.checkDataExportStatus(dlInProgress);
            });
          }, 30000); // Interval set to 30 seconds
        }
      },
      startReportsTimer: function() {
        if (!this.reportsTimer) {
          this.reportsTimer = setInterval(() => {
            this.backgroundReportsProcessing.forEach(rptInProgress => {
              this.checkReportStatus(rptInProgress);
            });
          }, 30000); // Interval set to 30 seconds
        }
      },
      stopDownloadsTimer: function() {
        clearInterval(this.downloadsTimer);
        this.downloadsTimer = null;
      },
      stopReportsTimer: function() {
        clearInterval(this.reportsTimer);
        this.reportsTimer = null;
      },
    },
    watch: {
      '$route' (to, from) {
        if (to.fullPath !== from.fullPath) {
          performance.mark("routeChange");
          const event = new Event('routeChange');
          window.dispatchEvent(event);
        }
      },
      loggedIn() {
        if (this.loggedIn) {
          this.checkSessionExpires();
        }
      },
      worklistNotifications() {
        if (this.worklistNotifications.includes("NewWorklistVersion")) {
          this.toggleReleaseNoteDisplay();
        }
      },
      currUser() {
        if (this.loggedIn) {
          this.checkPasswordExpiration();
        }
      },
      downloadsInProgress() {
        if (this.downloadsInProgress.length > 0) {
          this.toggleNav('notifications')
          this.startDownloadsTimer();
        } else {
          this.stopDownloadsTimer();
        }
      },
      completedDownloads() {
        if (this.completedDownloads.length > 0 && !this.nav['notifications']) {
          this.toggleNav('notifications')
        }
      },
      backgroundReportsProcessing() {
        if (this.backgroundReportsProcessing.length > 0) {
          this.startReportsTimer();
        } else {
          this.stopReportsTimer();
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.downloadsTimer);
      this.downloadsTimer = null;
      clearInterval(this.reportsTimer);
      this.reportsTimer = null;
    }
  };
</script>

<style lang="scss" scoped>
  /* .renderTheScss {
    width: 1px;
  } */

  .fa-spinner-third {
    color: #405d93;
  }

  header .vrule {
    border-right: solid 3px #CDCDCD;
    margin: 10px 1rem 10px -15px;
  }

  #customer-logo {
    padding: 10px;
    height: 59px;
    width: auto;
    max-width: 350px;
    overflow: hidden;
  }

  header.readOnlyUser
  {
    top: 3rem; 
  }

  #readOnlyUserHeader {
    height: 3rem;
    background-color: $brand-color3;
    padding-top: 1rem;
    position: sticky;
    top: 0;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    color: $color-base1;
    line-height: 1;
    z-index: 99;
  }

</style>

import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';
import logger from '@/shared/logger'
import QueryString from 'query-string-for-all'
import { defineAsyncComponent } from 'vue'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
    store.dispatch('auth/CHECK_SESSION_TOKEN').then(() => {
        if (!store.state.auth.loggedIn) {
            next()
            return
        }
        next('/')
    })
}

const isNullOrUndefined = (value) => {
    return value === null || value === undefined
}

const ifAuthenticated = async (to, from, next) => {
    let twoFARequired = JSON.parse(sessionStorage.getItem('twoFactorRequired'))
    let twoFAEnabled = JSON.parse(sessionStorage.getItem('twoFactorEnabled'))
    let twoFactorVerified = JSON.parse(sessionStorage.getItem('twoFactorVerified'))

    // check to see if a token was passed in for account linking
    // with gorilla
    const params = QueryString.parse(window.location.search)
    if (params.linkAccount) {
        store.dispatch('gorilla/SET_GORILLA_LINK_TOKEN', params.token)
    }

    try {
        await store.dispatch('auth/CHECK_SESSION_TOKEN')

        if (twoFARequired && !twoFAEnabled) {
            next('/TwoFactor')
            return
        }

        if (twoFAEnabled && !twoFactorVerified) {
            next('/TwoFactor')
            return
        }

        if (store.state.auth.loggedIn) {
            if (
              !store.state.customerSettings ||
              !store.state.providers ||
              !store.state.portalList ||
              !store.state.payerList ||
              !store.state.stempList ||
              !store.state.workers.length ||
              !store.state.dataList.Roles.length
            ) {
                // connect to the websocket after authenticating
                store.dispatch('socketConnection/connectToSocket')

                // update the temp storage to null
                store.dispatch('SET_USER_NOTIFICATIONS_IN_TEMP_STORAGE', null)

                // fetch application dependencies
                await store.dispatch('GET_SHARED_PA_INFO')
            }

            logger.trackSignInEvent()   

            if (!store.state.portalLogins) store.dispatch('GET_PORTAL_LOGINS');
            if (!store.state.displayMessages.messages) store.dispatch('displayMessages/GET_UMS_MESSAGES', Vue.prototype.$browserDetect.isIE);
            if (isNullOrUndefined(store.state.customerLogos.currentCustomerLogo)) store.dispatch("customerLogos/GET_CUSTOMER_LOGO")
            if (!store.state.requirementConfidenceThresholds) store.dispatch('medicalNecessity/GET_REQ_CONFIDENCE_THRESHOLDS')
            if (!store.state.linkedAccounts) store.dispatch('GET_LINKED_ACCOUNTS')
            if (isNullOrUndefined(store.state.hospitalNetwork)) store.dispatch('GET_HOSPITAL_NETWORK');
            if (!store.state.submissionMethods) store.dispatch('GET_SUBMISSION_METHODS')
            if (!store.state.upcomingReleaseNotes) store.dispatch('GET_UPCOMING_RELEASE_NOTES')

            store.dispatch('auth/REFRESH_AUTH_TOKEN')
            next()
            return
        }

        next('/SignIn')
    } catch (error) {
        console.log(error)
    }
}

const checkForTwoFA = (to, from, next) => {
    let twoFARequired = JSON.parse(sessionStorage.getItem('twoFactorRequired'))
    let twoFAEnabled = JSON.parse(sessionStorage.getItem('twoFactorEnabled'))
    let twoFactorVerified = JSON.parse(sessionStorage.getItem('twoFactorVerified'))

    store.dispatch('auth/CHECK_SESSION_TOKEN').then(() => {
        if (store.state.auth.loggedIn) {
            next()
            return
        }
        next('/SignIn')
    })
}

const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'Worklist',
            component: defineAsyncComponent(() => import(/* webpackChunkName: "worklist" */ '../components/Worklist.vue')),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/archive',
            name: 'Archive',
            component: () => import('../components/Archive.vue'),            
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/userworklist/:userId',
            name: 'UserRequests',
            component: defineAsyncComponent(() => import(/* webpackChunkName: "worklist" */ '../components/Worklist.vue')),
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/request/:requestId',
            name: 'requestView',
            component: defineAsyncComponent(() => import(/* webpackChunkName: "paRequest" */ '../components/Request.vue')),
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/reports',
            name: 'Reports',
            component: () => import('../components/Reports.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/reports/:reportId',
            name: 'Report',
            component: () => import('../components/Report.vue'),
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/DynamicReport',
            component: () => import('../components/DynamicReport.vue'),
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '',
                    name: 'DynamicReportWithInput',
                    props: route => ({
                        params: route.query.params ? JSON.parse(decodeURIComponent(route.query.params)) : {}
                    }),
                },
                {
                    path: ':reportResultsId(\\d+)', // Keep using route parameters for this route
                    name: 'DynamicReportWithId',
                    props: true
                }
            ]
        },
        {
            path: '/UserProfile/ChangePassword',
            name: 'ChangePassword',
            component: () => import('../components/User.ChangePassword.vue'),
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/SignIn',
            name: 'SignIn',
            component: () => import('../components/SignIn.vue'),
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/TwoFactor',
            name: 'TwoFactor',
            component: () => import('../components/TwoFactor.vue'),
            beforeEnter: checkForTwoFA
        },
        {
            path: '/TwoFactor/SetupApp',
            name: 'SetupApp',
            component: () => import('../components/TwoFactor.SetupApp.vue'),
            beforeEnter: checkForTwoFA
        },
        {
            path: '/TwoFactor/SetupSMS',
            name: 'SetupSMS',
            component: () => import('../components/TwoFactor.SetupSMS.vue'),
            beforeEnter: checkForTwoFA
        },
        {
            path: '/ForgotPassword',
            name: 'ForgotPassword',
            component: () => import('../components/ForgotPassword.vue'),
            props: true
        },
        {
            path: '/ResetPassword',
            name: 'ResetPassword',
            component: () => import('../components/ResetPassword.vue'),
            props: true
        },
        {
            path: '/UserManagement',
            name: 'UserManagement',
            component: () => import('../components/UserManagement.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/userprofile',
            name: 'UserProfile',
            component: () => import('../components/UserProfile.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/csm/CustomerSettings',
            name: 'ManagePayers',
            component: () => import('../components/ManagePayers.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/SystemSettings',
            name: 'SystemSettings',
            component: () => import('../components/SystemSettings.vue'),
            beforeEnter: ifAuthenticated,
        },        
        {
            path: '/managesystemuser/:paPersonId',
            name: 'ManageUser',
            component: () => import('../components/UserProfile.vue'),
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
          path: '/sso/availity',
          name: 'SSOAvaility',
          component: () => import('../components/SingleSignOn.vue'),
          props: true,
          beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/healthHelpQuestionnaire',
            name: 'HealthHelpQuestionnaire',
            component: () => import('../components/DirectSubmit.Questionnaire.HH.vue'),
            beforeEnter: ifAuthenticated,
            props: true,
        },
        {
            path: '/404',
            name: 'NotFound',
            component: () => import('../components/NotFoundPage.vue'),
        },
        {
            path: '*',
            redirect: '/404'
        },
    ]

})

export default router